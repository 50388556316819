import React from 'react'
import ReactApexChart from 'react-apexcharts'

const Graph = ({data,graph}) => {
    const series= [{
        name: 'Users',
        data:graph
      }]
       
     const options= {
        
        dataLabels: {
          enabled: false
        },
        
        grid: {
            show: false,
          },
       
        
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: data
         
        },
        yaxis: {
          title: {
            text: 'Users',
          },
        },
       
      }
  return (
    <div>
          <ReactApexChart options={options}  series={series}  type="bar" height={450} />
    </div>
  )
}

export default Graph