import React from "react";
import { NavLink } from "react-router-dom";

export default function Sidebar() {
  return (
    <nav id="sidebar">
      <div className="navbar-nav theme-brand flex-row  text-center">
        <div className="nav-logo">
          <div className="nav-item theme-logo">
            <NavLink to="/analytics">
              <img
                src={require("../src/assets/img/logo2.svg").default}
                className="navbar-logo"
                alt="logo"
              />
            </NavLink>
          </div>
        </div>
        <div className="nav-item sidebar-toggle">
          <div className="btn-toggle sidebarCollapse">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
           
          </div>
        </div>
      </div>
      <div className="shadow-bottom"></div>
      <ul className="list-unstyled menu-categories" id="accordionExample">
      <li
          className={
            window.location.pathname == "/dashboard"
              ? " menu active"
              : "menu"
          }
        >
          <NavLink
            to="/dashboard"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              <span>Dashboard</span>
            </div>
          </NavLink>
        </li>

        <li
          className={
            window.location.pathname == "/analytics"
              ? " menu active"
              : "menu"
          }
        >
          <NavLink
            to="/analytics"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-sliders"
              >
                <line x1="4" y1="21" x2="4" y2="14"></line>
                <line x1="4" y1="10" x2="4" y2="3"></line>
                <line x1="12" y1="21" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12" y2="3"></line>
                <line x1="20" y1="21" x2="20" y2="16"></line>
                <line x1="20" y1="12" x2="20" y2="3"></line>
                <line x1="1" y1="14" x2="7" y2="14"></line>
                <line x1="9" y1="8" x2="15" y2="8"></line>
                <line x1="17" y1="16" x2="23" y2="16"></line>
              </svg>
              <span>Analytics</span>
            </div>
          </NavLink>
        </li>
        <li
          className={
            window.location.pathname == "/user-management"
              ? " menu active"
              : "menu"
          }
        >
          <NavLink
            to="/user-management"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              <span>Users Management</span>
            </div>
          </NavLink>
        </li>

        {/* <li
          className={
            "menu " +
            (window.location.pathname == "/communities" ? "active" : "")
          }
        >
          <NavLink
            to="/communities"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-users"
              >
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg>
              <span>Communities</span>
            </div>
          </NavLink>
        </li> */}

        <li
          className={
            "menu " +
            (window.location.pathname == "/notification" ? "active" : "")
          }
        >
          <NavLink
            to="/notification "
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-bell"
              >
                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
              </svg>

              <span>Notifications</span>
            </div>
          </NavLink>
        </li>

        <li
          className={
            "menu " + (window.location.pathname == "/support" ? "active" : "")
          }
        >
          <NavLink
            to="/support"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-help-circle"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <span>Support</span>
            </div>
          </NavLink>
        </li>

     
        {/* <li
          className={
            "menu " +
            (window.location.pathname == "/reward-management" ? "active" : "")
          }
        >
          <NavLink
            to="/reward-management"
            aria-expanded="false"
            className="dropdown-toggle"
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-gift"
              >
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
              </svg>
              <span>Reward Management</span>
            </div>
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
}
