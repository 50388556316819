import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./Component/layouts/vertical-dark-menu/css/dark/loader.css";

import "./Component/src/bootstrap/css/bootstrap.min.css";
import "./Component/layouts/vertical-dark-menu/css/dark/plugins.css";

import "./Component/src/assets/css/dark/main.css";
import "./Component/src/plugins/css/dark/perfect-scrollbar/perfect-scrollbar.css";
import "./Component/layouts/vertical-dark-menu/css/dark/structure.css";

import "./Component/src/plugins/src/highlight/styles/monokai-sublime.css";

import "./Component/src/plugins/src/table/datatable/datatables.css";
import "./Component/src/plugins/css/light/table/datatable/dt-global_style.css";

import "./Component/src/plugins/css/light/table/datatable/custom_dt_custom.css";

import "./Component/src/plugins/css/dark/table/datatable/dt-global_style.css";
import "./Component/src/plugins/css/dark/table/datatable/dt-global_style.css";

import "./Component/src/plugins/css/dark/table/datatable/custom_dt_custom.css";

import "./Component/src/assets/css/light/authentication/auth-cover.css";

import "./Component/layouts/vertical-dark-menu/css/dark/plugins.css";

import "./Component/src/assets/css/dark/authentication/auth-cover.css";
import "./Component/src/assets/css/widgets/modules-widgets.css";
import "./Component/src/assets/css/dark/apps/contacts.css";



import store from "./Component/Redux/Store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
