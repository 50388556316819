import React, { useState } from "react";
import Master from "../Include/Master";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { changepasswordaction } from "../Redux/Action";

export default function ChangePassword() {
  const [csscontrol, setCsscontrol] = useState("");
  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Password is required!";
    } else if (!values.new_password) {
      errors.new_password = "Password is  required!";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match!";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,

    onSubmit: (values) => {
      dispatch(changepasswordaction(values));
    },
  });

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              // href="index.html"
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
              onClick={togalfuntionality}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Change password</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="row layout-spacing mt-5">
        <div className="col-lg-5 mx-auto">
          <h2>Change Password</h2>
          {/* <p>Enter your Old and New Password</p> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="widget-content widget-content-area p-5">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label" for="old-password">
                    Old Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    name="old_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.old_password}
                  />
                  {!formik.values.old_password && formik.errors.old_password ? (
                    <p style={{ color: "white" }}>
                      {formik.errors.old_password}
                    </p>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label className="form-label" for="new-password">
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    name="new_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.new_password}
                  />
                  {!formik.values.new_password && formik.errors.new_password ? (
                    <p style={{ color: "white" }}>
                      {formik.errors.new_password}
                    </p>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label className="form-label" for="confirm-password">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword3"
                    name="confirm_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_password}
                  />
                  {formik.errors.confirm_password ? (
                    <p style={{ color: "white" }}>
                      {formik.errors.confirm_password}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="col-4 mx-auto">
                <div className="mb-4 mt-5">
                  <button className="btn btn-secondary w-100" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Master>
  );
}
