import React from 'react'
import ReactApexChart from 'react-apexcharts';

const PieChart = ({ series, labels }) => {
  // console.log(series?.[2],labels,"series");
    const options = {
        labels: labels,
      //   theme: {
      //     monochrome: {
      //         enabled: false
      //     }
      // },
        chart: {
          type: "donut",
          // foreColor: '#ffffff'
          
        },
        // colors: [series?.[2]==0 ?'#f0f0f0':""],
        legend: {
          show: false,
        },
        dataLabels: { enabled: false },
        // tooltip: { enabled: false },
  //         fill: {
  //   colors: ["#f44336", "#ff9800", "#4caf50"]
  // },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              // size: '90%',
              dataLabels: {
                enabled: false
              },
              labels: {
                show: true,
                name: { show: true },
                value: {
                  show: true,
                  color: '#ffffff',
                },
                
                total: {
                  show: true,
                  showAlways: true,
                  color: '#ffffff',

                  // formatter: function (w) {
                  //   const totals = w.globals.seriesTotals;
    
                  //   const result = totals.reduce((a, b) => a + b, 0);
    
                  //   return (result / 1000).toFixed(3);
                  // }
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  )
}

export default PieChart