import React from "react";
import { NavLink } from "react-router-dom";
import ChangePassword from "../Modals/ChangePassword";
import { useState } from "react";
import Cookies from "universal-cookie";
export default function Header(props) {
  const cookie=new Cookies()
  //   const hide_and_show = () => {
  //     props.func(
  //       props.text == "sidebar-enable vertical-collpsed"
  //         ? ""
  //         : "sidebar-enable vertical-collpsed"
  //     );
  //   };
  const [show, setShow] = useState(false);

  return (
    <header className="header navbar navbar-expand-sm expand-header profile_right_side">
      <ul className="navbar-item theme-brand flex-row  text-center">
        <li className="nav-item theme-logo">
          <NavLink to="/user-management">
            <img
              src={require("../src/assets/img/logo2.svg").default}
              className="navbar-logo"
              alt="logo"
            />
          </NavLink>
        </li>
      </ul>
      <ChangePassword show={show} setShow={setShow} />
      <ul className="navbar-item flex-row ms-lg-auto ms-0 action-area">
        <li className="nav-item theme-toggle-item">
          <a href="#;" className="nav-link theme-toggle"></a>
        </li>

        <li className="nav-item dropdown user-profile-dropdown  order-lg-0 order-1 ">
          <a
            href="#;"
            className="nav-link dropdown-toggle user"
            id="userProfileDropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div className="avatar-container">
              <div className="avatar avatar-sm avatar-indicators avatar-online">
                <img
                  alt="avatar"
                  src={require("../src/assets/img/placeholder-img.svg").default}
                  className="rounded-circle"
                />
              </div>
            </div>
          </a>

          <div
            className="dropdown-menu position-absolute"
            aria-labelledby="userProfileDropdown"
          >
            <div className="dropdown-item mt-2">
              <NavLink onClick={() => setShow(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-lock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>{" "}
                <span>Change Password</span>
              </NavLink>
            </div>
            <div className="dropdown-item">
              <NavLink
                onClick={() => {
                  sessionStorage.clear();
                  cookie.remove('authentication')
                  window.location.href = "/";
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>{" "}
                <span>Log Out</span>
              </NavLink>
            </div>
          </div>
        </li>
      </ul>
    </header>
  );
}
