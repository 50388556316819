import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

export default function Master(props) {

  // const [togal_css, Settogal_css] = useState('');
  // const pull_data = (data) => {
  //   Settogal_css(data);
  // };

  return (
    //    <div id="load_screen"> <div className="loader"> <div className="loader-content">
    //    <div className="spinner-grow align-self-center"></div>
    // </div></div></div>

    <div>
      <div className="header-container">
        <Header />
      </div>

      <div className={"main-container " + props.func} id="container">
        <div className="overlay show"></div>
        <div className="cs-overlay"></div>
        <div className="search-overlay"></div>

        <div className="sidebar-wrapper sidebar-theme">
          <Sidebar />
        </div>

        <div id="content" className="main-content">
          <div className="layout-px-spacing">
            <div className="middle-content container-xxl1 p-0">
              {props.children}
            </div>
          </div>

          <div className="footer-wrapper">
            <div className="footer-section f-section-1">
              <p className="">
                Copyright © <span className="dynamic-year">2023</span>
                <a
                  target="_blank"
                  href="https://designreset.com/cork-admin/"
                ></a>
                , All rights reserved.
              </p>
            </div>
            {/* <div className="footer-section f-section-2">
               <p className="">Coded with <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg></p>
           </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
