import React from "react";
import Master from "../Include/Master";
import { useState } from "react";
import GenderGraph from "./graphs/GenderGraph";
import Graph from "./graphs/Graph";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics } from "../Redux/Action";

export default function Analytics() {
  const [csscontrol, setCsscontrol] = useState("");
  const [graphVal, setGraphVal] = useState({
    age: "",
    looking_for: "",
    interest: "",
    gender: "",
  });
  const [dataVal, setDataVal] = useState({
    ageData: "",
    looking_forData: "",
    interestData: "",
    genderData: "",
  });

  const dispatch = useDispatch();
  const analyticAgeData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.ageObject
  );
  const analyticLookinFor = useSelector(
    (state) => state?.reducerdata?.analytic_list?.brings_mil2milobj
  );
 
  const analyticInterestData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.interestObj
  );
  const analyticGenderData = useSelector(
    (state) => state?.reducerdata?.analytic_list?.genderObject
  );

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };
  useEffect(() => {
    dispatch(getAnalytics());
  }, []);

  useEffect(() => {
    if (analyticAgeData != undefined) {
      var ageCountData = Object.keys(analyticAgeData)?.map(
        (key) => analyticAgeData[key]
      );
      var data = Object.keys(analyticAgeData).map((key) => key);
    }
    if (analyticLookinFor != undefined) {
      var lookingData = Object.keys(analyticLookinFor).map(
        (key) => analyticLookinFor[key]
      );
      var data1 = Object.keys(analyticLookinFor).map((key) => key);
    }

    if (analyticInterestData != undefined) {
      var interestData = Object.keys(analyticInterestData).map(
        (key) => analyticInterestData[key]
      );
      var data3 = Object.keys(analyticInterestData).map((key) => key);
    }
    if (analyticGenderData != undefined) {
      var genderData = Object.keys(analyticGenderData).map(
        (key) => analyticGenderData[key]
      );
      var data4 = Object.keys(analyticGenderData).map((key) => key);
    }

    setGraphVal((prevValue) => {
      return {
        ...prevValue,
        age: ageCountData,
        looking_for: lookingData,
        gender: genderData,
        interest: interestData,
      };
    });

    setDataVal((prevValue) => {
      return {
        ...prevValue,
        ageData: data,
        looking_forData: data1,
        genderData: data4,
        interestData: data3,
      };
    });
  }, [
    analyticAgeData,
    analyticLookinFor,
    analyticInterestData,
    analyticGenderData,
  ]);
  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Analytics</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>  
          </header>
        </div>
      </div>
      <div className="row layout-top-spacing">
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Looking for Graph</h4>
                  </div>
                </div>
                <Graph
                  graph={graphVal?.looking_for}
                  data={dataVal?.looking_forData}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart2" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartBar" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Gender Graph</h4>
                  </div>
                </div>
                <GenderGraph
                  genderData={dataVal?.genderData}
                  genderGraphData={graphVal?.gender}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="s-bar" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Interest Graph</h4>
                  </div>
                </div>
                <Graph
                  graph={graphVal?.interest}
                  data={dataVal?.interestData}
                />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart" className=""></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div id="chartMixed" className="col-xl-12 layout-spacing">
            <div className="statbox widget box box-shadow">
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12 justify-content-between d-flex align-items-center">
                    <h4>Age Graph</h4>
                  </div>
                </div>
                <Graph graph={graphVal?.age} data={dataVal?.ageData} />
              </div>
              <div className="widget-content widget-content-area">
                <div id="mixed-chart4" className=""></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
}
