import {
  LOGIN_SUCCESS,
  USER_LIST,
  SUPPORT_LIST,
  COMMUNITY_LIST,
  ADD_COMMUNITY,
  DELETE_LIST,
  NOTIFICATION_LIST,
  DELETE_NOTIFY,
  USER_DETAIL,
  INTEREST_LIST,
  CHANGE_PASSWORD,
  ADMIN_REWARD_POINTS,
  ADMIN_DASHBOARD_DATA,
  ANALYTIC_LIST,
 
} from "../Constant";

const initialization = {
  login: "",
  user_list: [],
  reward_points: "",
  dashboard_data: null,
  analytic_list: null,
};

const authreducer = (state = initialization, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
      };
    case USER_LIST:
      return {
        ...state,
        user_list: action.payload,
      };
    case SUPPORT_LIST:
      return {
        ...state,
        support_list: action.payload,
      };
    case ADD_COMMUNITY:
      return {
        ...state,
        add_community: action.payload,
      };
    case COMMUNITY_LIST:
      return {
        ...state,
        community_list: action.payload,
      };
    case DELETE_LIST:
      return {
        ...state,
        delete_list: action.payload,
      };
    case NOTIFICATION_LIST:
      return {
        ...state,
        notify_list: action.payload,
      };
    case DELETE_NOTIFY:
      return {
        ...state,
        delete_notify: action.payload,
      };
    case USER_DETAIL:
      return {
        ...state,
        user_detail: action.payload,
      };
    case INTEREST_LIST:
      return {
        ...state,
        interest_list: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case ADMIN_REWARD_POINTS:
      return {
        ...state,
        reward_points: action.payload,
      };
    case ADMIN_DASHBOARD_DATA:
      return {
        ...state,
        dashboard_data: action.payload,
      };
    case ANALYTIC_LIST:
      return {
        ...state,
        analytic_list: action.payload,
      };
    default:
      return state;
  }
};
export default authreducer;
