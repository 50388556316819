import React from "react";
import Master from "../Include/Master";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Intersetlist, ShowprofileAction } from "../Redux/Action";
import { useParams } from "react-router";
import arrow_right from "../../../src/Component/src/assets/img/arrow_right.svg";
import { Button, Fade, Modal } from "@mui/material";

const Userprofile = () => {
  const IMG = process.env.REACT_APP_IMAGE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [viewModalImg, setViewModalImg] = useState(false);
  const [photoIndex, setphotoIndex] = useState();
  const [multipleImages, setMultipleImages] = useState(false);
  useEffect(() => {
    dispatch(ShowprofileAction(id));
  }, [id]);

  useEffect(() => {
    // dispatch(Intersetlist());
  }, []);

  const userdetail = useSelector((state) => state?.reducerdata?.user_detail);
  console.log(userdetail,"userdetail");
  const interestname1 = useSelector((state) => state?.reducerdata?.interest_list);
  
  const interestname = interestname1?.filter((item) =>
  userdetail?.interest_in?.includes(item._id)
  );
  console.log(interestname1,interestname);

  const [csscontrol, setCsscontrol] = useState("");

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };

  const image1 = [IMG + "user_document/" + userdetail?.main_image];

  let images = userdetail?.multi_image?.map(
    (image) => IMG + "user_document/" + image
  );
  console.log(images,"images");
  const onImageClickk = (index) => {
    setphotoIndex(parseInt(index));
    setMultipleImages(true);
  };
  const handleClose = () => {
    setViewModalImg(false);
    setMultipleImages(false);
  };

  return (
    <>
      <Master func={csscontrol}>
        <div className="middle-content container-xxl1 p-0">
          {/* <!--  BEGIN BREADCRUMBS  --> */}
          <div className="secondary-nav">
            <div
              className="breadcrumbs-container"
              data-page-heading="Analytics"
            >
              <header className="header navbar navbar-expand-sm">
                <a
                  onClick={togalfuntionality}
                  className="btn-toggle sidebarCollapse"
                  data-placement="bottom"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-menu"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </a>
                <div className="d-flex breadcrumb-content">
                  <div className="page-header">
                    <div className="page-title"></div>

                    <nav
                      className="breadcrumb-style-one"
                      aria-label="breadcrumb"
                    >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#">Profile</a>
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </header>
            </div>
          </div>
          {/* <!--  END BREADCRUMBS  --> */}
          <div className="page-meta">
            <nav className="breadcrumb-style-one" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/user-management">User Management</NavLink>
                </li>
                <li className="arrow_right">
                  <img src={arrow_right} />
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  User Detail
                </li>
              </ol>
            </nav>
          </div>

          <div className="row layout-top-spacing"></div>
          <div className="row layout-top-spacing">
            <div className="col-md-7 mb-3">
              <div className="widget-content widget-content-area">
                <div className="detail_page_content box_p_30">
                  <div className="d-flex mb-2 align-items-center">
                    <div className="main_profile_img">
                      <img
                        onClick={() => setViewModalImg(true)}
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "user_document/" +
                          userdetail?.main_image
                        }
                      />
                    </div>
                    <div className="user-nam-details">
                      <div className="d-flex user-name-part">
                        <h4>
                          {userdetail?.full_name}
                          <span> {userdetail?.age}</span>
                        </h4>
                      </div>
                      <div className="gamer-name">
                        {" "}
                        <label htmlFor="">
                          Country : <span>{userdetail?.country_name?userdetail?.country_name:"N/A"}</span>
                        </label>
                      </div>
                      <div className="gamer-name">
                        {" "}
                        <label htmlFor="">
                          Gender : <span>{userdetail?.gender?userdetail?.gender:"N/A"}</span>
                        </label>
                      </div>
                    </div>
                  </div>
{/* 
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="about-section">
                        <div className="label-view">About</div>
                        <div className="label-view-text">
                          {userdetail?.about_me}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="row mt-4 ">
                    <div className="col-md-12">
                      <div className="label-view">Interest</div>
                      <ul className="list-none p-0 interest-list">
                        {userdetail?.interest_in?.map((item, i) => {
                          return (
                            <li key={i} className="badge-light-dark m-15">
                              {/* <img
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "Icon/" +
                                  item?.icon
                                }
                                alt="icon "
                              /> */}
                              <p>{item?.intrest_name}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-4 ">
                    <div className="col-md-12">
                      <div className="label-view">What I am looking for </div>
                      <div className="label-view-text">
                        {userdetail?.brings_mil2mil}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-4">
                    <div className="col-md-12">
                      <h4 className="org-text mt-5">Additional information</h4>
                      <div className="course_info row other-dtails ">
                     
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Zodiac sign</div>
                          <div className="label-view-text">
                            {userdetail?.zodiac_sign}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Race</div>
                          <div className="label-view-text">
                            {userdetail?.race}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Sexual Orientation</div>
                          <div className="label-view-text">
                            {userdetail?.sexual_orientation}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Gender Identity</div>
                          <div className="label-view-text">
                            {userdetail?.gender_identity}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Drug Usage</div>
                          <div className="label-view-text">No</div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Vape / Smoke</div>
                          <div className="label-view-text">
                            {userdetail?.vape_smoke}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Drink Alcohol</div>
                          <div className="label-view-text">
                            {userdetail?.drink_alcohal}{" "}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Love Language</div>
                          <div className="label-view-text">
                            {userdetail?.love_language}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Looking for</div>
                          <div className="label-view-text">
                            {userdetail?.looking_for}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Pronouns</div>
                          <div className="label-view-text">
                            {userdetail?.pronouns}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Work</div>
                          <div className="label-view-text">
                            {userdetail?.work}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">User is looking for</div>
                          <div className="label-view-text">
                            {userdetail?.user_looking_for}
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="label-view">Intimacy preference</div>
                          <div className="label-view-text">
                            {userdetail?.intimacy_preference}
                          </div>
                        </div>
                     
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-5 mb-3">
              {/* <div className="widget-content widget-content-area box_p_30">
                <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h4>Video</h4>
                </div>
                <div className="video-part">
                  <video
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      "user_document/" +
                      userdetail?.video
                    }
                    alt=""
                    width="100%"
                    height="100%"
                    controls="controls"
                    autoplay="true"
                  />
                </div>
              </div> */}

              <div className="widget-content widget-content-area mt-5 box_p_30">
                <div>
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                    <h4>Gallery</h4>
                  </div>
                </div>
                <div className="row">
                  {userdetail?.multi_image?.map(function (image_obj, i) {
                    return (
                      <div
                        className="col-xl-3 col-lg-6 col-md-6 col-sm-6 mb-4"
                        key={i}
                      >
                        <a
                          // href="src/assets/img/lightbox-1.jpg"
                          onClick={() => {
                            onImageClickk(i);
                          }}
                          className="defaultGlightbox glightbox-content height_fixed"
                        >
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "user_document/" +
                              image_obj
                            }
                            alt="image"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {viewModalImg == true && multipleImages == false && (
          <Modal
            className={"modal_center"}
            open={viewModalImg}
            onClose={handleClose}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={"modal_center"}>
              <Fade className={"modal_center"} in={viewModalImg} timeout={500}>
                <img
                  src={image1[0]}
                  alt="asd"
                  style={{ maxHeight: "90%", maxWidth: "90%" }}
                />
              </Fade>
            </div>
          </Modal>
        )}

        {multipleImages && (
          <Modal
            // className={classes.modal}
            open={multipleImages}
            onClose={handleClose}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div>
              {photoIndex == 0 ? (
                ""
              ) : (
                <Button
                  className={"img_side_pre"}
                  onClick={() => setphotoIndex(photoIndex - 1)}
                >
                  <img
                    src={require("../../../src/Component/src/assets/img/left_icon.png")}
                  />
                </Button>
              )}
              <div className={"modal_center add_mt_100"}>
                <Fade in={multipleImages} timeout={500}>
                  <img
                    src={images[photoIndex]}
                    style={{ maxHeight: "90%", maxWidth: "90%" }}
                  />
                  {/* <Button>Text</Button> */}
                </Fade>
              </div>
              {images?.length - 1 == photoIndex ? (
                ""
              ) : (
                <Button
                  className={"img_side_next"}
                  onClick={() => setphotoIndex(photoIndex + 1)}
                >
                  <img
                    src={require("../../../src/Component/src/assets/img/right_icon.png")}
                  />
                </Button>
              )}
            </div>
          </Modal>
        )}
      </Master>
    </>
  );
};

export default Userprofile;
