import axios from "axios";

console.log(process.env.REACT_APP_API_URL, "process.env.REACT_APP_API_URL");
const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: { Authorization: sessionStorage.getItem("authentication") },
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        sessionStorage.clear();
        window.location.reload(false);
        window.location.href = "/";
      }, 1000);
    }
  }
);

export default API;
