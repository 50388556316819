import { toast } from "react-toastify";
import API from "./ApiConfig/ApiConfig";
import {
  CHANGE_PASSWORD,
  COMMUNITY_LIST,
  DELETE_LIST,
  DELETE_NOTIFY,
  INTEREST_LIST,
  NOTIFICATION_LIST,
  USER_DETAIL,
  USER_LIST,
  ADMIN_REWARD_POINTS,
  ADMIN_DASHBOARD_DATA,
  ANALYTIC_LIST,
 
} from "./Constant";
import { SUPPORT_LIST } from "./Constant";
import Cookies from "universal-cookie";
var cookies = new Cookies();
export const Adminlogin = (loginobject,rememberMe) => async (dispatch) => {
  try {
    const loginstatus = await API.post("/Adminlogin", loginobject);
    console.log(loginstatus,"loginstatus");

    if (rememberMe && loginstatus.data.status == 200) {
      cookies.set("authentication", loginstatus.data.data.token, {
        path: "/",
        expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
      });
      sessionStorage.setItem("authentication", loginstatus.data.data.token);
      window.location.href = "/dashboard";
    } else if (!rememberMe && loginstatus?.data.status == 200) {
      sessionStorage.setItem("authentication", loginstatus.data.data.token);
      window.location.href = "/dashboard"}
    
    else {
      toast.warning(loginstatus.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Userslist = () => async (dispatch) => {
  try {
    const list = await API.get("/AdminList");
    if (list.data.status == 200) {
      dispatch({
        type: USER_LIST,
        payload: list.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const banuserAction = (data) => async (dispatch) => {
  try {
    const ban = await API.post(`/Banded`, data);
    if (ban.data.status == 200) {
      dispatch(Userslist());
      toast.success(ban.data.message)
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const activeUser = (data) => async (dispatch) => {
  try {
    const active = await API.get("/DeactivetAccount", data);
    if (active.data.status == 200) {
      dispatch(Userslist());
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const Supportlist = () => async (dispatch) => {
  try {
    const suplist = await API.get("/SupportList");
    console.log(suplist);
    if (suplist.data.status == 200) {
      dispatch({
        type: SUPPORT_LIST,
        payload: suplist.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Addcommunity = (obj_data) => async (dispatch) => {
  try {
    const communityadd = await API.post("/AddCommunity", obj_data);
    if (communityadd.data.status == 200) {
      toast.success(communityadd.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Getcommunity = () => async (dispatch) => {
  try {
    const community = await API.get("/Getcommunitylistadmin");
    if (community.data.status == 200) {
      dispatch({
        type: COMMUNITY_LIST,
        payload: community.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Deletecommunity = (id) => async (dispatch) => {
  try {
    const deletecommunity = await API.get(`/CommunityDelete?id=${id}`);
    if (deletecommunity.data.status == 200) {
      toast.success(deletecommunity.data.message);
      dispatch({
        type: DELETE_LIST,
        payload: deletecommunity.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const addNotifications = (data) => async (dispatch) => {
  const res = await API.post("/AdminNotification", data);
  if (res.data.status == 200) {
    toast.success(res.data.message);
    dispatch(getNotifications());
  }

  return res;
};

export const getNotifications = (data) => async (dispatch) => {
  const response = await API.get("/AdminNotificationList");
  if (response?.data.status == 200) {
    dispatch({
      type: NOTIFICATION_LIST,
      payload: response.data.data,
    });
  }
};

export const deleteNotifications = (id) => async (dispatch) => {
  try {
    const notifydelete = await API.get(`/AdminNotificaitonDelete?id=${id}`);
    if (notifydelete.data.status == 200) {
      toast.success(notifydelete.data.message);
      dispatch({
        type: DELETE_NOTIFY,
        payload: notifydelete.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const ShowprofileAction = (id) => async (dispatch) => {
  try {
    const profile = await API.get(`/profileDetail?id=${id}`);
    console.log(profile,'profile');

    if (profile.data.status == 200) {
      dispatch({
        type: USER_DETAIL,
        payload: profile.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Intersetlist = () => async (dispatch) => {
  try {
    const interest = await API.get("/Interest_list");
    if (interest.data.status == 200) {
      dispatch({
        type: INTEREST_LIST,
        payload: interest.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const changepasswordaction = (object) => async (dispatch) => {
  try {
    const changepass = await API.post("/AdminChangePassword", object);
    if (changepass?.data?.status == 200) {
      toast.success(changepass.data.message);
      sessionStorage.clear();
      window.location.href = "/";
    } else {
      toast.warning(changepass.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getRewardPoints = () => async (dispatch) => {
  try {
    const response = await API.get("/getAdminRewardPoints");
    if (response?.data?.status == 200) {
      dispatch({
        type: ADMIN_REWARD_POINTS,
        payload: response?.data?.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const manageRewardPoints = (data) => async (dispatch) => {
  try {
    const response = await API.put("/manageRewardPoints", data);
    if (response.data.status == 200) {
      dispatch(getRewardPoints());
      toast.success(response?.data?.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const getDashboardData = () => async (dispatch) => {
  try {
    const response = await API.get("/dashboard");
    if (response.data.status == 200) {
      dispatch({
        type: ADMIN_DASHBOARD_DATA,
        payload: response.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
export const getAnalytics = () => async (dispatch) => {
  try {
    const response = await API.get("/analytics");
    console.log(response,'response');
    if (response.data.status == 200) {
      dispatch({
        type: ANALYTIC_LIST,
        payload: response?.data?.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};
