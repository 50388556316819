import React from "react";
import Master from "../Include/Master";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Addcommunity, Deletecommunity, Getcommunity } from "../Redux/Action";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";

export default function Communities() {
  const [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(0); //Pagination

  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = () => setShowdelete(true);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");

  useEffect(() => {
    dispatch(Getcommunity());
  }, []);

  const communitylist = useSelector(
    (state) => state.reducerdata.community_list
  );

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      community: Yup.mixed().nullable().required("Community Image is required"),
      logo: Yup.mixed().nullable().required("Logo is required"),
    }),
    onSubmit: (values) => {
      const formdata = new FormData();
      formdata.append("title", values.title);
      formdata.append("id", "");
      formdata.append("community", values.community);
      formdata.append("logo", values.logo);
      formik.resetForm();
      dispatch(Addcommunity(formdata)).then((value) => {
        dispatch(Getcommunity());
      });

      setShow(false);
    },
  });

  const [csscontrol, setCsscontrol] = useState("");

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };

  const [search, setSearch] = useState("");

  const PER_PAGE = 10;
  const pageCount = Math.ceil(communitylist?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * PER_PAGE;

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              // href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Communities</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      {/* <div className="fix-sticky-c">
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={handleShow}>
              Add New Community
            </Button>
          </div>
        </div>
      </div>
      </div> */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add a Community!</Modal.Title>
        </Modal.Header>

        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="form-group mt-3">
              <label htmlFor="defaultForm-name" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="form-group mt-3">
              {formik.errors.title ? (
                <div className="error">{formik.errors.title}</div>
              ) : null}

              <label htmlFor="defaultForm-name" className="form-label">
                Community
              </label>
              <input
                type="file"
                name="community"
                onChange={(e) => {
                  formik.setFieldValue("community", e.target.files[0]);
                }}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {formik.errors.community ? (
                <div className="error">{formik.errors.community}</div>
              ) : null}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="defaultForm-name " className="form-label">
                Logo
              </label>
              <input
                type="file"
                name="logo"
                onChange={(e) => {
                  formik.setFieldValue("logo", e.target.files[0]);
                }}
                onBlur={formik.handleBlur}
                className="form-control"
              />
              {formik.errors.logo ? (
                <div className="error">{formik.errors.logo}</div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Seacrh Bar */}
      <div className="row">
        <div className="col-md-6 col-sm-6 d-flex justify-content-sm-start justify-content-center">
          <div className="dataTables_length" id="style-3_length">
            <div className="d-flex justify-content-center mt-3">
              <Button variant="primary" onClick={handleShow}>
                Add New Community
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 d-flex justify-content-end mt-sm-0 mt-3">
          <div id="style-3_filter" className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                aria-controls="style-3"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="row layout-spacing mt-3">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <table
                id="style-3"
                className="table style-1 dt-table-hover non-hover sticky-head-second"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Community Name</th>
                    <th>Community Image</th>
                    <th>Join Member</th>
                    {/* <th>Members</th> */}
                    {/* <th>Post</th> */}
                    <th className="">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {communitylist?.length > 0 ? communitylist
                    ?.filter((object) =>
                      search == ""
                        ? object
                        : object?._id.title?.toLowerCase().includes(search)
                    )
                    ?.slice(offset, offset + PER_PAGE)
                    ?.map(function (user_object, i) {
                      return (
                        <tr key={i}>
                          <td> {offset + i + 1} </td>
                          <td className="user-name">
                            <a
                              className="profile-img"
                              // href="javascript: void(0);"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "community/" +
                                  user_object._id?.logo
                                }
                                alt="logo"
                              />
                            </a>

                            <a className="name_space">
                              {user_object?._id?.title}
                            </a>
                          </td>
                          <td className="">
                            <a
                              className="profile-img"
                              // href="javascript: void(0);"
                            >
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  "community/" +
                                  user_object._id?.image
                                }
                                alt="community"
                              />
                            </a>
                          </td>
                          <td className="">
                            <a className="profile-img">
                              {user_object?.total_user_join}
                            </a>
                          </td>

                          {/* <td>{user_object.._id?community_members}</td> */}
                          {/* <td>{user_object.._id?post}</td> */}
                          <td className="text-left">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                setShowdelete(true);
                                setId(user_object?._id?._id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    }) :  <p className="no_content">No Content Yet</p>}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showdelete}
        onHide={handleClosedelete}
        size="sl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(Deletecommunity(id));
              dispatch(Getcommunity());
              setShowdelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Master>
  );
}
