import React from "react"

export default function TermConditions(){
    return(

        <div className="row layout-spacing mt-3">
            <div className="col-lg-12">
                <div className="statbox widget box box-shadow py-4">
                    <div className="container">
                    <br/>  <h2>Term & Condition</h2><br/>
                        <p>These Terms of Use ("Terms") govern your use of the Mil2Mil mobile application ("App") and its associated services ("Services"). By using the App and Services, you agree to abide by these Terms. Please read them carefully.</p>
                        
                        <br/> <h5>1. Acceptance of Terms</h5><br/>
                        <p>By using Mil2Mil, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App or Services.</p>
                   
                        <br/> <h5>2. Eligibility</h5><br/>
                    <p>You must be at least 18 years old to use Mil2Mil. By using the App, you represent and warrant that you meet this eligibility requirement. </p>

                    <br/> <h5>3. User Content </h5><br/>
                    <ul>
                        <li>
                        <b>Responsible Use:</b> You are solely responsible for any content you post, including text, images, audio, or other forms of communication. You agree not to use the App or Services for any unlawful, harmful, or inappropriate purposes. 
                        </li>
                        <li>
                        <b>Prohibited Content:</b> You may not post or share content that is defamatory, offensive, abusive, harassing, or violates the rights of others, including intellectual property rights. 
                        </li>
                    </ul>

                    <br/><h5>4. Groups and Topics </h5><br/>
                    <ul>
                        <li>
                        <b>Group Creation:</b> Mil2Mil allows users to create groups and engage in audio discussions based on predefined topics. You may create, join, or leave groups at your discretion. 
                        </li>
                        <li><b>Group Rules:</b> Groups may have specific rules and guidelines set by group administrators. You agree to follow these rules when participating in group discussions.</li>
                    </ul>

                    <br/> <h5>5. Privacy</h5><br/>
                    <p><b>Privacy Policy:</b> Your use of Mil2Mil is subject to our Privacy Policy, which governs the collection, use, and sharing of your personal information. Please review our Privacy Policy for more information.</p>
                   
                    <br/> <h5>6. Security and Account Access </h5><br/>
                   <p><b>Account Security:</b> You are responsible for maintaining the security of your account credentials. Do not share your password or access with others. Notify us immediately if you suspect unauthorized access to your account.</p>
                    
                   <br/><h5>7. Termination </h5><br/>
                    <ul>
                        <li>Termination by Users: You may terminate your use of Mil2Mil at any time by deleting your account.</li>
                        <li>  Termination by Mil2Mil: We reserve the right to terminate or suspend your account and access to the App and Services at our discretion, without prior notice, if you violate these Terms or engage in harmful or inappropriate conduct.</li>
                    </ul>

                    <br/><h5>8. Changes to Terms </h5><br/>
                    <p>Mil2Mil may update these Terms from time to time. We will notify users of material changes through the App or via email. Continued use of the App and Services after such changes constitutes acceptance of the updated Terms.</p>
                    
                    <br/><h5>9. Disclaimer of Warranty </h5><br/>
                    <p>Mil2Mil is provided "as is" and "as available." We do not warrant that the App or Services will be error-free, uninterrupted, or free from harmful components.</p>
                    
                    <br/><h5>10. Limitation of Liability </h5><br/>
                    <p>Mil2Mil shall not be liable for any indirect, consequential, or incidental damages arising out of your use or inability to use the App or Services.</p>
                    

                    <br/><h5>11. Governing Law </h5><br/>
                    <p>These Terms are governed by the laws of [Your Jurisdiction]. </p>

                    <br/><h5>12. Contact Us </h5><br/>
                    <p>If you have any questions or concerns regarding these Terms, please contact us at <a href="mailto:mil2mildating@gmail.com.">mil2mildating@gmail.com.</a>  </p>
                    </div>
                </div>
            </div>
        </div>
    )
}