import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { changepasswordaction } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";

const ChangePassword = ({ show, setShow }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  //   const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleSave = () => {
    setSubmitClicked(true);
  };

  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Old Password is required!";
    } else if (!values.new_password) {
      errors.new_password = "Password is required!";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Passwords Doesn't match!";
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,

    onSubmit: (values) => {
      //   console.log(values);
      dispatch(changepasswordaction(values));
      formik.resetForm();
      handleClose();
    },
  });

  useEffect(() => {
    if (show == true) {
      formik.resetForm();
      setSubmitClicked(false);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="sl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label" for="old-password">
                Old Password
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword3"
                name="old_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.old_password}
              />
              {submitClicked && formik.errors.old_password ? (
                <p style={{ color: "white" }}>{formik.errors.old_password}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label className="form-label" for="new-password">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword3"
                name="new_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.new_password}
              />
              {submitClicked &&
              !formik.values.new_password &&
              formik.errors.new_password ? (
                <p style={{ color: "white" }}>{formik.errors.new_password}</p>
              ) : null}
            </div>
            <div className="mb-3">
              <label className="form-label" for="confirm-password">
                Confirm Password
              </label>
              <input
                type="password"
                className="form-control"
                id="inputPassword3"
                name="confirm_password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirm_password}
              />
              {formik.values.confirm_password &&
              formik.errors.confirm_password ? (
                <p style={{ color: "white" }}>
                  {formik.errors.confirm_password}
                </p>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangePassword;
