import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Adminlogin } from "../Redux/Action";
import Cookies from "universal-cookie";
import signLogo from "../src/assets/img/sign-logo.svg"
export default function Login() {
  const cookie=new Cookies()
  const dispatch = useDispatch();
  const [signInClick, setSignInClick] = useState(false);
  const [rememberMe, setRemember] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Email required*";
    }
    if (!values.password) {
      errors.password = "Password required*";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      setSignInClick(false);
      dispatch(Adminlogin(values,rememberMe));
    },
  });
  useEffect(()=>{
    if(sessionStorage.getItem("authentication")||cookie.get("authentication")){
        if(cookie.get("authentication")&& !sessionStorage.getItem("authentication")){
            sessionStorage.setItem("authentication",cookie.get("authentication"))
        }
        window.location.href="/dashboard"
    }
      },[])
  return (
    <div className="auth-container d-flex">
      <div className="container mx-auto align-self-center">
        <div className="row">
          <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
            <div className="auth-cover-bg-image"></div>
            <div className="auth-overlay"></div>

            <div className="auth-cover">
              <div className="position-relative">
                <img
                  src={signLogo}
                  alt="auth-img"
                />
              </div>
            </div>
          </div>

          <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-5 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto">
            <div className="card">
              <div className="card-body">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <h2>Sign in</h2>
                      <p>Enter your email and password to login</p>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="email"
                          autoComplete="off"
                        />

                        {formik.errors.email && signInClick ? (
                          <div className="error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-4">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.errors.password && signInClick ? (
                          <div className="error">{formik.errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <div className="form-check form-check-primary form-check-inline check_point_align">
                          <input
                            className="form-check-input me-3"
                            type="checkbox"
                            id="form-check-default"
                            onChange={(e) =>
                                    setRemember(e.target.checked)
                                  }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="form-check-default"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-4">
                        <button
                          onClick={() => setSignInClick(true)}
                          type="submit"
                          className="btn btn-secondary w-100"
                        >
                          SIGN IN
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
