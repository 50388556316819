import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Master from "../Include/Master";
import { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import {
  addNotifications,
  deleteNotifications,
  getNotifications,
} from "../Redux/Action";
import ReactPaginate from "react-paginate";

const Notification = () => {
  const [id, setId] = useState("");

  const [showdelete, setShowdelete] = useState(false);
  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = () => setShowdelete(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0); //Pagination
  const [show, setShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const dispatch = useDispatch();

  const notifyList = useSelector((state) => state?.reducerdata?.notify_list);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Enter title";
    }
    if (!values.description) {
      errors.description = "Write some content";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      gender: "",
      title: "",
      description: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        await dispatch(addNotifications(values));
        dispatch(getNotifications());
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [csscontrol, setCsscontrol] = useState("");

  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };

  const PER_PAGE = 10;
  const pageCount = Math.ceil(notifyList?.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  const offset = currentPage * PER_PAGE;

  return (
    <Master func={csscontrol}>
      {/* <!-- BREADCRUMB --> */}
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              // href="javascript:void(0);"
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
              onClick={togalfuntionality}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Notifications</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>

      {/* <!-- Add Button Modal --> */}

      {/* <div className="fix-sticky-c">
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={() => {setShow(true);formik.resetForm();}}>
              Add Notification
            </Button>
          </div>
        </div>
      </div>
      </div>   */}

      {/* Search Bar */}
      <div className="row">
        <div className="col-md-6 col-sm-6 d-flex justify-content-sm-start justify-content-center">
          <div className="dataTables_length" id="style-3_length">
            <div className="d-flex justify-content-center mt-3">
              <Button
                variant="primary"
                onClick={() => {
                  setShow(true);
                  formik.resetForm();
                }}
              >
                Add Notification
              </Button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 d-flex justify-content-end mt-sm-0 mt-3">
          {/* <div id="style-3_filter" className="dataTables_filter">
                      <label><input type="search" className="form-control" placeholder="Search..." aria-controls="style-3" onChange={(e)=>{
                        setSearch(e.target.value)
                      }} /></label>
                      </div> */}
        </div>
      </div>

      <div className="row layout-top-spacing">
        <div className="ccol-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <table
                id="zero-config style-3"
                className="table style-1 dt-table-hover non-hover sticky-head-second"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>To</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notifyList?.length > 0 ? notifyList
                    ?.filter((object) =>
                      search == ""
                        ? object
                        : object.title.toLowerCase().includes(search)
                    )
                    ?.slice(offset, offset + PER_PAGE)
                    ?.map((item, i) => {
                      return (
                        <tr>
                          <td>{offset + i + 1}</td>
                          <td>{item?.title}</td>
                          <td>
                            <p className="line_break">{item?.description}</p>
                          </td>

                          <td>
                            {item.gender && item.gender == 1
                              ? "All Male"
                              : item.gender == 3
                                ? "All Users"
                                : "All Female"}
                          </td>
                          <td className="custom_pd">
                            {/* <a className="delete_ml">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                 strokeLinecap="round"
                                strokeLinejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a> */}

                            <a
                              onClick={() => {
                                setShowdelete(true);
                                setId(item?._id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </a>
                          </td>
                        </tr>
                      );
                    }) :  <p className="no_content">No Content Yet</p>}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName="active"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showdelete}
        onHide={handleClosedelete}
        size="sl"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosedelete}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              dispatch(deleteNotifications(id));
              dispatch(getNotifications());
              setShowdelete(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- Modal --> */}
      <Modal show={show}>
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <Modal.Header
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setShow(false)}
          >
            <Modal.Title>Add Notification</Modal.Title>
            <Button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow(false)}
            ></Button>
          </Modal.Header>

          <Modal.Body>
            <form action="">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <select
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        formik.setFieldValue("gender", e.target.value)
                      }
                    >
                      <option value="" selected>
                        Select Users
                      </option>
                      <option value="1">All Male</option>
                      <option value="0">All Female</option>
                      <option value="2">All Other</option>
                      <option value="3">All Users</option>
                    </select>
                    <span className="validation-text"></span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Title"
                    />
                    <span className="validation-text">
                      {formik.errors.title ? (
                        <p style={{ color: "red" }}>{formik.errors.title}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <textarea
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      name="description"
                      id=""
                      cols="30"
                      rows="4"
                      className="form-control"
                      placeholder="Description"
                    ></textarea>
                    <span className="validation-text">
                      {formik.errors.description ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.description}*
                        </p>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {/* <!-- <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Discard</button> --> */}

            {formik.values.title && formik.values.description ? (
              <Button
                type="submit"
                className="btn drk_btn"
                onClick={() => setShow(false)}
              >
                Notify
              </Button>
            ) : (
              <Button type="submit" className="btn drk_btn">
                Notify
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </Master>
  );
};

export default Notification;
