import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    console.log(props.rewardPoint,"rewardPoint");
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rewards_modal'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Reward Points
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row  ">
        <div className="col-lg-12">
        
          <div className="statbox widget box box-shadow p-0">
            <div className="widget-content widget-content-area">
              <form >
                <div className="row">
                  <div className="col-md-6">
                    <div className="rewardmng">
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Email verification
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          value={props?.rewardPoint?.email_points}
                          name="email_points"
                          placeholder=""
                          disabled
                      
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          SMS verification
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="sms_points"
                          value={props?.rewardPoint?.sms_points}
                          disabled
                     
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Enable notifications
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="notification_points"
                          value={props?.rewardPoint?.notification_points}
                      disabled
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Enable location permissions
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="location_points"
                          value={props?.rewardPoint?.location_points}
                          disabled
                        //   value={formik.values.location_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Add snapchat link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="snapchat_points"
                          value={props?.rewardPoint?.snapchat_points}
                          disabled
                        //   value={formik.values.snapchat_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Add Instagram link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="instagram_points"
                          value={props?.rewardPoint?.instagram_points}
                          disabled
                        //   value={formik.values.instagram_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="rewardmng">
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Add TikTok Link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="tiktok_points"
                          value={props?.rewardPoint?.tiktok_points}
                          disabled
                        //   value={formik.values.tiktok_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Add Twitter link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="twitter_points"
                          value={props?.rewardPoint?.twitter_points}
                          disabled
                        //   value={formik.values.twitter_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Add Spotify link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="spotify_points"
                          value={props?.rewardPoint?.spotify_points}
                          disabled
                        //   value={formik.values.spotify_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Follow us on Instagram
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="instagram_follow_points"
                          value={props?.rewardPoint?.instagram_follow_points}
                          disabled
                        //   value={formik.values.instagram_follow_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Follow us on TikTok
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="tiktok_follow_points"
                          value={props?.rewardPoint?.tiktok_follow_points}
                          disabled
                        //   value={formik.values.tiktok_follow_points}
                        //   onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                 
                </div>
              </form>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Reply
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <svg> ... </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row mb-4">
                        <div className="col-sm-12">
                          <label>Content</label>
                          <div id="blog-description"></div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn drk_btn">
                        Reply
                      </button>
                      <button type="button" className="btn btn btn-light-dark">
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

function PointsModal({modalShow,setModalShow,rewardPoint}) {

  return (
    <>
 
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        rewardPoint={rewardPoint}
      />
    </>
  );
}

export default PointsModal