import React, { useState } from 'react'


export default function PrivacyPolicy() {
 

  return (

      <div className="row layout-spacing mt-3">
        <div className="col-lg-12">
            <div className="statbox widget box box-shadow py-4">
                <div className="container">
                    <h2>Privacy Policy</h2>
                    <p>This Privacy Policy ("Policy") is designed to help you understand how Mil2Mil ("we," "us," or "our") collects, uses, discloses, and safeguards your personal information when you use our mobile application, Mil2Mil, and its associated services ("Services"). By using Mil2Mil, you consent to the practices described in this Policy.</p>
                    <br/><h5>Information We Collect</h5><br/>
                    <b>User-Provided Information:</b> When you register for Mil2Mil or use our Services, you may provide us with certain personal information, including but not limited to your name, email address, profile picture, and date of birth. Additionally, you may choose to provide information about your interests and preferences.
                    <p>Content Shared: Mil2Mil allows users to create groups and engage in audio discussions. Content shared within these groups, including audio chats, messages, and topics discussed, may be collected and stored on our servers.</p>

                    <p>Device Information: We may automatically collect certain information about your device, such as your device type, operating system, IP address, and device identifiers.</p>

                    <p> Usage Information: We collect information about your interactions with our Services, including the date and time of your activity, the content you view, and your interactions with other users.</p>

                    <br/>
                   <h5>How We Use Your Information</h5> 

                   <br/>
                   <p>We use the information we collect for various purposes, including:</p> 



                    <p>Providing and Improving the Services: We use your information to provide, maintain, and improve Mil2Mil and its features, such as creating and managing groups, facilitating audio chats, and enhancing user experiences.
                    Communication: We may use your contact information to send you updates, notifications, and relevant information about Mil2Mil.
                    Personalization: We use your information to personalize your experience, recommend groups, topics, and connections based on your interests and usage patterns.
                    Analytics: We analyze user data to understand how our Services are used, diagnose technical issues, and improve our platform.
                    We may share your personal information in the following circumstances:</p>



                    <p>With Other Users: Mil2Mil is a social platform, and certain information, such as your profile name and picture, may be visible to other users within the app.</p>


                    <p>With Group Members: Content shared within groups, including audio chats and messages, is visible to group members.</p>


                    <p>With Third-Party Service Providers: We may share your information with third-party service providers who help us operate, maintain, and improve our Services.</p>


                    <p>Legal Compliance: We may disclose your information to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property, and those of our users or the public.</p>


                    <br/><h5>Security</h5><br/>


                   <p> We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>



                   <br/><h5>Your Choices</h5><br/>
                   <p>You can control and manage certain information through your Mil2Mil account settings. You may also have the right to access, correct, or delete your personal information. Contact us at [contact email] to exercise these rights.
</p> 


<br/> <h5>Changes to this Privacy Policy</h5><br/>
                   <p>We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any material changes by posting the revised Policy on our website or within the app.</p> 



                   <br/><h5>Contact Us</h5> <br/>
                   <p>If you have any questions, concerns, or requests related to this Privacy Policy or our data practices, please contact us at <a href="mailto:mil2mildating@gmail.com."> mil2mildating@gmail.com. </a></p> 
                </div>
            </div>
        </div>
    </div>
  )
}
