import React from 'react'
import ReactApexChart from 'react-apexcharts';

const GenderGraph = ({genderData,genderGraphData}) => {
    const series= [{
        name:"Users",
          data: genderGraphData
        }]
         
        const options = {
          
          // chart: {
          //   type: "bar",
          //   height: 500
          // },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            grid: {
              show: false,
            },
          xaxis: {
              categories: genderData
            },
        
        };
  return (
    <div>
        <ReactApexChart options={options}  series={series}  type="bar" height={450} />
    </div>
  )
}

export default GenderGraph