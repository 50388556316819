import React, { useState, useEffect } from "react";
import Master from "../Include/Master";
import { useDispatch, useSelector } from "react-redux";
import { getRewardPoints, manageRewardPoints } from "../Redux/Action";
import { useFormik } from "formik";

const RewardMng = () => {
  const [csscontrol, setCsscontrol] = useState("");
  const togalfuntionality = () => {
    setCsscontrol(
      csscontrol == "sidebar-closed sbar-open" ? "" : "sidebar-closed sbar-open"
    );
  };
  const reward_points = useSelector((state) => state?.reducerdata?.reward_points);
  console.log(reward_points,'reward_points');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRewardPoints());
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email_points: reward_points?.email_points,
      sms_points: reward_points?.sms_points,
      notification_points: reward_points?.notification_points,
      location_points: reward_points?.location_points,
      snapchat_points: reward_points?.snapchat_points,
      instagram_points: reward_points?.instagram_points,
      tiktok_points: reward_points?.tiktok_points,
      twitter_points: reward_points?.twitter_points,
      spotify_points: reward_points?.spotify_points,
      instagram_follow_points: reward_points?.instagram_follow_points,
      tiktok_follow_points: reward_points?.tiktok_follow_points,
    },
    onSubmit: (values) => {
      const responseObject = {};
      for (let key in values) {
        if (values[key] != "") {
          responseObject[key] = values[key];
        }
      }
      dispatch(manageRewardPoints(responseObject));
    },
  });

  return (
    <Master func={csscontrol}>
      <div className="secondary-nav">
        <div className="breadcrumbs-container" data-page-heading="Analytics">
          <header className="header navbar navbar-expand-sm">
            <a
              href="javascript:void(0);"
              onClick={togalfuntionality}
              className="btn-toggle sidebarCollapse"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <div className="d-flex breadcrumb-content">
              <div className="page-header">
                <div className="page-title"></div>

                <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Reward Management</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="row layout-spacing mt-3">
        <div className="col-lg-12">
          <div className="page-meta mt-2 mb-3">
            <nav className="breadcrumb-style-one" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a className="" href="#">
                    Reward Management
                  </a>
                </li>
              </ol>
            </nav>
          </div>
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="rewardmng">
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Email verification
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          value={formik.values.email_points}
                          name="email_points"
                          placeholder=""
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          SMS verification
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="sms_points"
                          value={formik.values.sms_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Enable notifications
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="notification_points"
                          value={formik.values.notification_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Enable location permissions
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="location_points"
                          value={formik.values.location_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Add snapchat link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="snapchat_points"
                          value={formik.values.snapchat_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Add Instagram link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="instagram_points"
                          value={formik.values.instagram_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="rewardmng">
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Add TikTok Link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="tiktok_points"
                          value={formik.values.tiktok_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Add Twitter link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="twitter_points"
                          value={formik.values.twitter_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="old-password">
                          Add Spotify link
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="spotify_points"
                          value={formik.values.spotify_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="new-password">
                          Follow us on Instagram
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="instagram_follow_points"
                          value={formik.values.instagram_follow_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                      <div className="mb-3 reward_input_flex">
                        <label className="form-label" for="confirm-password">
                          Follow us on TikTok
                        </label>
                        <input
                          min={1}
                          type="number"
                          className="form-control"
                          id="inputPassword3"
                          name="tiktok_follow_points"
                          value={formik.values.tiktok_follow_points}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-3 mb-4">
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </form>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Add Reply
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <svg> ... </svg>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row mb-4">
                        <div className="col-sm-12">
                          <label>Content</label>
                          <div id="blog-description"></div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn drk_btn">
                        Reply
                      </button>
                      <button type="button" className="btn btn btn-light-dark">
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default RewardMng;
